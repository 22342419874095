import React from 'react'
/** @jsx jsx */
import {
  jsx,
  Box,
  Grid,
  Heading,
  Text,
  Divider,
  Button,
  Link as ThemeLink,
} from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import { CallToBook, Rooms, SEO } from 'components'
import BunkHouses from '../components/bunk-houses'

function Accommodation() {
  const accommodationQuery = useStaticQuery(graphql`
    query AccommodationImages {
      bunkHouses: file(relativePath: { eq: "bunk-houses-new.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stayWithUs: file(relativePath: { eq: "stay-with-us.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Accommodation" />
      <BackgroundImage
        fluid={accommodationQuery.stayWithUs.childImageSharp.fluid}
        backgroundColor={`#040e18`}
        sx={{ py: ['calc(30% - 45px)', 'calc(15% - 45px)'], px: 'm' }}
      >
        <Heading
          variant="heading.xxl"
          sx={{ color: 'white.100', textAlign: 'center' }}
        >
          Stay with us
        </Heading>
      </BackgroundImage>

      <Box sx={{ bg: 'black.5' }}>
        <Box
          sx={{
            py: ['xl', null, 'xxl', 'xxxl'],
            px: 'm',
            maxWidth: 'measure',
            mx: 'auto',
            boxSizing: 'content-box',
          }}
        >
          <Heading variant="heading.l" mb={['s']}>
            Our rooms
          </Heading>
          <Box as="p">
            All of the rooms at The Station Inn are named after the shanty towns
            that used to populate the large area of land known as Batty Moss.
            Over 3000 labourers were employed to build the viaduct. Construction
            started in 1870 and the amazing project was completed in 1875. The
            labourers lived in ramshackle construction camps which stretched
            from just behind the Station Inn's wall up to the foot of Whernside.
            The foundations of the shanty towns are still visible if you know
            what to look for. We have named our rooms to tip our hat to the
            determination and bravery of these workers. Whilst the shanties were
            rough and dangerous places, we hope our rooms provide a safer and
            warmer environment for you to spend a night!
          </Box>
        </Box>
      </Box>

      <Box px="m">
        <Grid variant="boxes.container" mx="auto" py={['xl', null, 'xxxxl']}>
          <Rooms includeTypes />
        </Grid>
      </Box>

      <Grid
        gap={['xl', null, 'xxl']}
        bg="green.dark"
        py={['xl', 'xxl', 'xxxl']}
        px={['m', null, 'l']}
      >
        <Grid
          bg="white.100"
          variant="boxes.container"
          mx="auto"
          columns={[null, null, '1fr 1fr', '2fr 3fr']}
          gap={0}
        >
          <Box
            p={['m', 'l', 'xl', 'xxl']}
            my="auto"
            sx={{ order: [2, null, 1] }}
          >
            <Text variant="subhead" mb="s">
              Bunk Rooms
            </Text>
            <Heading variant="heading.xl">Bunk houses</Heading>

            <Divider />

            <Text as="p" mb="l">
              If you want a warm bed on a budget with a wonderful pub on your
              doorstep, The Station Inn's bunkhouses are ideal for groups. Our
              bunk houses are basic but excellent value - all you need is a
              sleeping bag, we provide undersheets and pillow cases. Bunk houses
              aren't just for intrepid adventurers, we welcome all kinds of
              groups who enjoy the atmosphere and convenience of traditional
              accommodation which is somewhere between camping and a bed and
              breakfast.
            </Text>

            <Box mb="xl">
              <Button as="a" href="mailto:bookings@thestationinnribblehead.com">
                Email to enquire
              </Button>
            </Box>

            <CallToBook>to book a bunkhouse</CallToBook>
          </Box>

          <BackgroundImage
            fluid={accommodationQuery.bunkHouses.childImageSharp.fluid}
            backgroundColor={`#040e18`}
            sx={{ pb: ['56%', null, null, 0], order: [1, null, 2] }}
          />
        </Grid>

        <BunkHouses />
      </Grid>
    </>
  )
}

export default Accommodation
